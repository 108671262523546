/* text-Colors.......... */
.greyColor {
  color: #7e7e7e !important;
}
.dGreyColor {
  color: #464a53 !important;
}
.dGreenColor {
  color: #248d6c !important;
}
.dBlackColor {
  color: #000000 !important;
}

/* border Radius */

.br-8 {
  border-radius: 8px;
}
.br-12 {
  border-radius: 12px;
}
.br-14 {
  border-radius: 14px;
}

/* font-Size............. */
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}

/* font-Weight......... */

.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}

/* Back ground Color....... */

.bgRed {
  background-color: #f44d4d !important;
  border: 1px solid #f44d4d !important;
}
.bgDGreen {
  background-color: #248d6c !important;
  border: 1px solid #248d6c !important;
}

/* cursor pointer....... */

.cursorPointer {
  cursor: pointer !important;
}

/* Givers Media css */
.giversMediaImgMainDiv {
  width: 146px;
  height: 146px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.giversMediaImgCheckBoxDiv {
  position: absolute;
  top: 5px;
  left: 5px;
}
.mediaGalleryAudioDiv {
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  background: #deeee9;
  width: 156px;
  height: 179px;
}

.audioDownloadbutton {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

/* package Button */
.pinkButton {
  background-color: #eca8a4 !important;
  border: 1px solid #eca8a4 !important;
}
.blueButton {
  background-color: #44c7ff !important;
  border: 1px solid #44c7ff !important;
}
.purpleButton {
  background-color: #9b00ff !important;
  border: 1px solid #9b00ff !important;
}

/* slider */

.sliderCardPink {
  border-radius: 20px;
  box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid #ffeeed;
  background: linear-gradient(180deg, #ffeeed 0%, #ffffff 43.75%);
  max-width: 361px;
  min-width: 305px;
}
.sliderCardBlue {
  border-radius: 20px;
  box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid #ffeeed;
  background: linear-gradient(180deg, #daf5ff 0%, #ffffff 43.75%);
  max-width: 361px;
  min-width: 305px;
}
.sliderCardPurple {
  border-radius: 20px;
  box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid #ffeeed;
  background: linear-gradient(180deg, #f5e6ff 0%, #ffffff 43.75%);
  max-width: 361px;
  min-width: 305px;
}

.sliderOptionPink {
  color: #eca8a4;
  font-weight: bold;
  font-size: 25px;
}

.sliderOptionBlue {
  color: #44c7ff;
  font-weight: bold;
  font-size: 25px;
}

.sliderOptionPurple {
  color: #9b00ff;
  font-weight: bold;
  font-size: 25px;
}

.sliderExplorePink {
  color: #eca8a4;
  font-weight: bold;
  font-size: 16px;
}

.sliderExploreBlue {
  color: #44c7ff;
  font-weight: bold;
  font-size: 16px;
}

.sliderExplorePurple {
  color: #9b00ff;
  font-weight: bold;
  font-size: 16px;
}

.slickListItem {
  padding-left: 15px !important;
  line-height: 20px;
}

/* videos............. */

.videoContentDiv {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 8px;
}
.videoContentDiv:hover {
  box-shadow: 0 8px 15px rgb(0 0 0 / 0.2);
}

.tag-del-btn {
  background: white !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 10px;
  color: #000000 !important;
}
.tag-del-btn:hover {
  background-color: none !important;
}

.tag-span {
  background: rgb(236, 236, 236) !important;
  border-radius: 8px;
}
/* blog select modal */
.blogModal {
  width: 150px;
  border: 1px solid gray;
  border-radius: 8px;
  overflow: hidden;
}

.blogSelectedModal {
  width: 150px;
  border: 3px solid rgb(73, 71, 71);
  border-radius: 8px;
  overflow: hidden;
}

/* ifrome for click disable solve */

iframe {
  position: unset !important;
}

/* for input file css */

.imageUpload > input {
  display: none;
}
.imageUpload {
  width: 204px;
  height: 170px;
  overflow: hidden;
  border: 3px dashed #e9e9e9;
  border-radius: 30px;
}
.imageUploadModal > input {
  display: none;
}

.openNotification {
  background-color: rgb(228, 228, 228);
}
